@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@font-face {
  font-family: 'Oslo Sans Bold';
  src: local('Oslo Sans Bold'),
    url(./assets/fonts/OsloSans-Bold.woff) format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Oslo Sans Light';
  src: local('Oslo Sans Light'),
    url(./assets/fonts/OsloSans-Light.woff) format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Oslo Sans Medium';
  src: local('Oslo Sans Medium'),
    url(./assets/fonts/OsloSans-Medium.woff) format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Oslo Sans Regular';
  src: local('Oslo Sans Regular'),
    url(./assets/fonts/OsloSans-Regular.woff) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Oslo Sans Regular Italic';
  src: local('Oslo Sans Regular Italic'),
    url(./assets/fonts/OsloSans-RegularItalic.woff) format('truetype');
  font-weight: 400;
  font-style: italic;
}

.osg-sans-bold {
  font-family: 'Oslo Sans Bold';
}

.osg-sans-light {
  font-family: 'Oslo Sans Light';
}
.osg-sans-medium {
  font-family: 'Oslo Sans Medium';
}
.osg-sans-regular {
  font-family: 'Oslo Sans Regular';
}
.osg-sans-regular-italic {
  font-family: 'Oslo Sans Regular Italic';
}

.osg-background-green-10 {
  background-color: #e5ffe6;
}

body {
  margin: 0;
  font-family: 'Oslo Sans Regular', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.oslo-truncate-hover {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:hover {
    overflow: unset;
    text-overflow: unset;
    white-space: unset;
  }
}

.oslo-table {
  div span {
    font-family: 'Oslo Sans Regular';
    font-size: 1rem;
    color: #2a2859;
    letter-spacing: normal;
    line-height: 1.5rem;
  }

  button span {
    color: inherit;
  }

  @for $i from 1 through 24 {
    .oslo-thead-#{$i} {
      .oslo-tr {
        display: grid;
        grid-template-columns: repeat($i, 1fr);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid black;
        font-weight: bold;

        .oslo-th-sort-desc::after,
        .oslo-th-sort-asc::after {
          content: ' ';
          position: relative;
          left: 2px;
          border: 5px solid transparent;
        }

        .oslo-th-sort-desc,
        .oslo-th-sort-asc {
          padding-right: 10px;
          overflow: hidden;
        }

        .oslo-th-sort-desc::after {
          top: 0.67rem;
          border-top-color: #2a2859;
        }

        .oslo-th-sort-asc::after {
          bottom: 0.67rem;
          border-bottom-color: #2a2859;
        }
      }
    }

    .oslo-tbody-#{$i} {
      .oslo-tr {
        display: grid;
        grid-template-columns: repeat($i, 1fr);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        &:hover {
          background-color: #b3f5ff;
        }
      }
    }

    .oslo-tfoot-#{$i} {
      .oslo-tr {
        display: grid;
        grid-template-columns: repeat($i, 1fr);
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        background-color: #ececec;
        font-weight: bold;
      }
    }

    @for $j from 1 to 24 {
      .oslo-th-#{$i}-span-#{$j} {
        grid-column: $i / span $j;
        text-align: left;
        margin: 0.5rem;
        overflow-x: hidden;
      }

      .oslo-td-#{$i}-span-#{$j} {
        grid-column: $i / span $j;
        text-align: left;
        display: flex;
        align-items: center;
        margin: 0.5rem;
        overflow-x: hidden;
      }
    }
  }

  .oslo-text-right {
    text-align: right !important;
    justify-content: end;
  }
}

/* REMOVES ARRORWS FROM NUMBER INPUT  */
input.no-arrow-input::-webkit-outer-spin-button,
input.no-arrow-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'].no-arrow-input {
  -moz-appearance: textfield;
}

select {
  display: block;
  border: 2px solid var(--color-blue-dark);
  border-radius: 0;
  color: var(--color-blue-dark);

  &:enabled:focus {
    border-color: var(--color-active);
    outline: 2px solid var(--color-active);
  }

  &:enabled:active {
    border-color: var(--color-active);
    outline: none;
  }
}
