@media screen {
  .print-me {
    display: none !important;
  }
}

@media print {
  
  #automaterSomIkkeSkalArkiveres, #automaterSomSkalArkiveres, #tommerundeModal {
    display: none !important;
  }

  div {
    width: 0;
    height: 0;
  }

  .print-me {
    z-index: 100000;
    display: block;
    background-color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  #autoamterTattUt {
    page-break-before: always;
    top: 100%;
  }
}

@page {
  size: landscape;
  margin: 0;
}
