.header-elements a {
  display: block;
  width: 10rem;
  height: 2rem;
  padding-top: 5px;
  &:hover {
    &:not(.active) {
      background-color: #3555b2;
      color: white;
    }
  }
}

.header-elements .active {
  background-color: #2a2859;
  color: white !important; // override link styling from punkt-css
  text-decoration: none;
}
