.sort-arrow {
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.5rem;
}

.sortable-header:hover .sort-arrow,
.sortable-header:focus .sort-arrow {
  background-color: #2a2859;
  fill: white;
}
